import styled, { keyframes } from "styled-components";

export const flexContainer = styled.div`
  flex-grow: 1;
`;
export const CenterDiv = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    text-align: center;
  }

  .subtitle {
    padding: 20px;
    max-width: 550px;
    text-align: center;
    margin:auto;
  }
`;

export const CenterDivRow = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const MainContainer = styled.div`
  width: 100%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
export const BackgroundVideo = styled.div`
  position: inherit;
  right: 0px;
  bottom: -135px;
  min-width: 100%;
  height: auto;

  video {
    height: auto;
    width: 100%;
     padding-top: 78px;
  }
`;

export const HeroText = styled.div`
  position: absolute;
  bottom: 30px;
  text-shadow: 1px 1px 3px #000000, 0px 0px 0px rgba(0, 0, 0, 0.3);
  width: 70%;
  padding-left: 2%;
  color: #f8f8f8;

  h1 {
    margin: -20px 0;
    padding: 20px 0;
    font-size: 7em !important;
    filter: drop-shadow(2px 4px 6px black);
    margin: -20px 0;
    padding: 20px 0 !important;
    text-transform: uppercase;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  p {
    text-decoration: none;
    color: #f8f8f8;
    margin: 0;
    font-size: 2.5vw;
    width: 85%;
    overflow-wrap: break-word;
    word-break: break-word;
  }
  @media only screen and (max-width: 800px) and (orientation: landscape){
    width: 80%;
    h1 {
      width: 96%;
      margin: auto;
      font-size: 10vw !important;
      overflow: hidden;
    }
    p {
      margin:auto;
      font-size:1.2em;
      width: 100%;
    }
  } 

  @media screen and (max-width: 500px) and (orientation: portrait){
    width: 100%;
    margin: auto;
    position: absolute;
    padding: 0;
    top:30vh;
    text-align: center;
    h1 {
      width: 96%;
      margin: auto;
      font-size: 12vw !important;
      overflow: hidden;
    }
    p {
      margin:auto;
      font-size:1.5em;
    }
  }
  @media only screen and (min-width: 500px) and (max-width: 1200px) and (orientation: portrait) {
    width: 100%;
    margin: auto;
    padding: 0;
    h1 {
      margin: 1% 2.5%;
      font-size: 6.5em !important;
    }
    p {
      font-size: xx-large;
      width: 90%;
      margin: 1% 2.5%;
      display: none;
    }
  }
`;

export const button = styled.button`
  color: white;
  text-align: center;
  background-color: #181818;
  font-size: 1rem;
  padding: 12px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;

  :hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
`;

export const bokabord = styled.button`
  font-family: StureplansgruppenUnderRubrik;
  text-transform: uppercase;
  width: 1200px;
  max-width: 600px;
  color: white;
  text-align: center;
  background-color: #181818;
  font-size: 1rem;
  padding: 12px;
  font-size: 19px;
  border-radius: 10px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  display:none;

  /* width on search bar to match the flex-grid */
  @media (max-width: 1250px) {
    max-width: 900px;
  }
  @media (max-width: 920px) {
    max-width: 600px;
  }
  @media (max-width: 600px) {
    max-width: 90%;
    font-size: 15px;
  }
  :hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
`;

export const HeroContainer = styled.div`
  height: 100%;
  width: 100%;
  background: linear-gradient(
    to left bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.7)
  );
  @media screen and (max-width: 500px) {
    background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0)
    );
  }
`;

export const Topic = styled.div`
  text-align: center;
  margin: 50px 0 0 0;
  h2 {
    font-size: 5vw;
  }
  @media screen and (max-width: 500px) {
    margin: 30px 0 0 0;
    h2 {
      font-size: 8vw;
    }
  }
`;

export const DotLoader = keyframes`
    0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
`;

export const LoadingDots = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    .loading-dots {
      animation: ${DotLoader} 1.5s infinite ease-in-out;
      background-color: #000;
      border-radius: 10px;
      display: inline-block;
      height: 10px;
      margin: 0 5px;
      width: 10px;
      
      &:nth-child(2) {
        animation-delay: .5s;
      }
      
      &:nth-child(3) {
        animation-delay: 1s;
      }
    }
`;
