import React from "react";
import * as Global from "../css/globalStyle";
import "../css/global.css";
import '@fortawesome/fontawesome-svg-core/styles.css';
import Header from "../components/header";
import Footer from "../components/footer";

const App = props => {
  return (
    <div>
      <Global.MainContainer>
        <Global.flexContainer>
          <Header />
          <div className="contentWrapper">{props.children}</div>
        </Global.flexContainer>
        <Footer />
      </Global.MainContainer>
    </div>
  );
};

export default App;
